import React, { useState } from 'react';
import { PageProps, navigate, graphql } from 'gatsby';

import { ProductsPageQuery } from '@lib/types';
import { Wrapper, SEO, Pagination, Icon, ProductThumbnail } from '@components';
import { search } from '@lib';

import * as s from './styles';

type PageContext = {
    currentPage: number;
    totalPages: number;
    slug: string;
};

const Products: React.FC<PageProps<ProductsPageQuery, PageContext>> = ({ data, pageContext }) => {
    const [status, setStatus] = useState<string>();
    const [searchText, setSearchText] = useState('');
    const [items, setItems] = useState(() => data.allUnifiedItemGroup.nodes);
    const categories = data.allMarkdownRemark.nodes;
    const { currentPage, totalPages, slug } = pageContext;

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        const path = `/products${value !== '*' ? `/${value}` : ''}`;

        navigate(path, { state: { shouldUpdateScroll: false } });
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value);

    const handleSearchSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        setSearchText('');
        setStatus('LOADING');
        search(searchText)
            .then(setItems)
            .then(() => setStatus('SEARCH'));
    };

    return (
        <Wrapper>
            <SEO title="Products" description="410 Smoke Shop products" />
            <h1 css={s.title}>Products</h1>
            <div css={s.controls}>
                <div css={s.control}>
                    <div>Categories</div>
                    <div>
                        <select onChange={handleSelectChange} value={slug}>
                            <option value="*">All</option>
                            {categories.map((category) => (
                                <option key={category.fields.slug} value={category.fields.slug}>
                                    {category.frontmatter.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button type="button" style={{ pointerEvents: 'none' }}>
                        <Icon name="carret-down" />
                    </button>
                </div>

                <form css={s.control} onSubmit={handleSearchSubmit}>
                    <div css={s.searchLabel(status)}>
                        <span>Search</span>
                        <div css={s.spinner} />
                    </div>
                    <div>
                        <input type="text" value={searchText} onChange={handleSearchChange} />
                    </div>
                    <button type="submit">
                        <Icon name="search" />
                    </button>
                </form>
            </div>

            <div css={s.itemList}>
                {items.map((node) => (
                    <div css={s.itemListing} key={node.name}>
                        <ProductThumbnail itemNode={node} />
                    </div>
                ))}
            </div>
            {status !== 'SEARCH' && (
                <Pagination
                    baseUrl={`/products${slug !== '*' ? `/${slug}` : ''}`}
                    currentPage={currentPage}
                    totalPages={totalPages}
                />
            )}
        </Wrapper>
    );
};

export default Products;

export const pageQuery = graphql`
    query ProductListQuery($itemSkip: Int!, $itemLimit: Int!, $slug: String!) {
        allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "//content/products/" } }
            sort: { fields: [frontmatter___title], order: ASC }
        ) {
            nodes {
                frontmatter {
                    title
                }
                fields {
                    slug
                }
            }
        }
        cloverMerchant(address: { city: { regex: "/pasadena/i" } }) {
            address {
                phoneNumber
            }
        }
        allUnifiedItemGroup(
            filter: {
                unifiedItems: {
                    elemMatch: {
                        rootCloverItem: {
                            code: { ne: null }
                            markdownFile: { extension: { eq: "md" } }
                            categories: {
                                elemMatch: {
                                    markdownFile: { childMarkdownRemark: { fields: { slug: { glob: $slug } } } }
                                    name: { nin: ["Website Hidden", "Delete"] }
                                }
                            }
                        }
                        cloverItems: { elemMatch: { itemStock: { quantity: { gt: 0 } } } }
                    }
                }
            }
            sort: { fields: name }
            limit: $itemLimit
            skip: $itemSkip
        ) {
            nodes {
                ...ItemGroupThumbnail
            }
        }
    }
`;
