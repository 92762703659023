import { css, keyframes } from '@emotion/react';

export const title = css`
    color: #ffb300;
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 4rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -0.15em;
        left: 0.4em;
        width: 1.2em;
        height: 0.3em;
        background-image: radial-gradient(circle, #ffb300 35%, transparent 0);
        background-size: 0.3em 0.3em;
        background-repeat: repeat-x;
        background-position: 0 0;
    }
`;

export const controls = css`
    margin-bottom: 2rem;

    @media (min-width: 50rem) {
        display: flex;

        form {
            margin-left: 1rem;
            flex: 1 1 50%;
        }
    }
`;

export const control = css`
    display: flex;
    position: relative;
    margin-bottom: 1rem;

    & > div:nth-of-type(1) {
        background: #ffb300;
        color: white;
        font-weight: 600;
        font-size: 0.9rem;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
    }

    & > div:nth-of-type(2) {
        flex: 1 1 100%;

        select,
        input {
            display: block;
            width: 100%;
            height: 100%;
            border: 2px solid #ffb300;
            border-top-right-radius: 0.2rem;
            border-bottom-right-radius: 0.2rem;
            padding: 0 2rem 0 1rem;
        }

        select {
            font-weight: 600;
            text-transform: uppercase;
        }

        input {
            padding: 0 2.5rem 0 1rem;
            font-size: 1rem;
        }

        option {
            color: #2d2d2d;
            font-weight: 600;
        }
    }

    button {
        box-sizing: border-box;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 2rem;
        background: white;
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        background: white;
        color: #ffb300;
        border: 2px solid #ffb300;
        padding: 0.2rem;
        cursor: pointer;
    }
`;

export const itemList = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: 28rem) {
        justify-content: flex-start;
    }
`;

export const itemListing = css`
    margin-bottom: 3rem;
    flex: 1 1 100%;
    max-width: 18rem;
    position: relative;

    @media (min-width: 28rem) {
        flex: 1 1 40%;
        max-width: 47%;

        &:not(:nth-of-type(2n)):not(:last-of-type) {
            margin-right: 6%;
        }
    }

    @media (min-width: 44rem) {
        flex: 1 1 26%;
        max-width: calc((100% - 14%) / 3);

        &:not(:nth-of-type(2n)):not(:last-of-type) {
            margin-right: 0;
        }
        &:not(:nth-of-type(3n)):not(:last-of-type) {
            margin-right: 7%;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 15%;
                right: -13%;
                width: 1px;
                height: 70%;
                background: #eee;
            }
        }
    }
`;

export const spin = keyframes`
from {
    transform: rotate(0);
}

to {
    transform: rotate(360deg);
}
`;

export const spinner = css`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: '';
        box-sizing: border-box;
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        border: 4px solid rgba(255, 255, 255, 0.4);
        border-top-color: rgba(255, 255, 255, 1);
        border-radius: 1rem;
        animation: ${spin} 0.7s linear infinite;
    }
`;

export const searchLabel = (status) => css`
    position: relative;

    & > span {
        display: block;
        transition: all 0.3s;
        opacity: ${status === 'LOADING' ? 0 : 1};
        transform: translateY(${status === 'LOADING' ? -0.5 : 0}rem);
    }

    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s;
        opacity: ${status === 'LOADING' ? 1 : 0};
        transform: translate(-50%, calc(-50% + ${status === 'LOADING' ? 0 : 0.5}rem));
    }
`;
